import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { industries } from '../data/industries';
import IndustryTemplate from '../components/DynamicSEO/IndustryTemplate';
import Header from '../components/Header';
import Footer from '../components/Footer';

interface DynamicIndustryPageParams {
  documentType: string;
  industry: string;
}

const validDocTypes = ['invoice', 'contract', 'proposal', 'receipt', 'quote', 'sow', 'brief', 'nda', 'timesheet', 'expense', 'status'];

export default function DynamicIndustryPage() {
  const { documentType: rawDocType, industry: rawIndustry } = useParams<keyof DynamicIndustryPageParams>();
  const documentType = rawDocType?.toLowerCase();
  const industry = rawIndustry?.toLowerCase();

  // Validate document type and industry
  if (!documentType || !industry) {
    return <Navigate to="/" replace />;
  }

  // Find the industry data
  const industryData = industries.find(ind => ind.slug === industry);
  if (!industryData) {
    return <Navigate to="/" replace />;
  }

  // Validate document type
  if (!validDocTypes.includes(documentType)) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="min-h-screen">
      <Header />
      <IndustryTemplate 
        industry={industry}
        documentType={documentType}
        benefits={industryData.benefits}
      />
      <Footer />
    </div>
  );
}