import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';

interface IndustryTemplateProps {
  industry: string;
  documentType: string;
  benefits: Array<{
    title: string;
    description: string;
    icon: any;
    color: string;
  }>;
}

export default function IndustryTemplate({ industry, documentType, benefits }: IndustryTemplateProps) {
  const capitalizedIndustry = industry.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const capitalizedDocType = documentType.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    'name': `Lipana ${capitalizedDocType} Generator for ${capitalizedIndustry}`,
    'applicationCategory': 'BusinessApplication',
    'offers': {
      '@type': 'Offer',
      'price': '0',
      'priceCurrency': 'USD'
    },
    'description': `Create professional ${documentType.toLowerCase()}s tailored for ${industry.toLowerCase()}. Free online ${documentType.toLowerCase()} generator with industry-specific templates and automatic calculations.`,
  };

  return (
    <>
      <Helmet>
        <title>{`${capitalizedDocType} Generator for ${capitalizedIndustry} | Free Professional Templates`}</title>
        <meta 
          name="description" 
          content={`Generate professional ${documentType.toLowerCase()}s specifically designed for ${industry.toLowerCase()}. Free online ${documentType.toLowerCase()} generator with industry-specific templates.`} 
        />
        <meta 
          name="keywords" 
          content={`${industry.toLowerCase()} ${documentType.toLowerCase()}, ${industry.toLowerCase()} template, professional ${documentType.toLowerCase()}, ${documentType.toLowerCase()} generator`} 
        />
        <meta 
          property="og:title" 
          content={`${capitalizedDocType} Generator for ${capitalizedIndustry} | Free Professional Templates`} 
        />
        <meta 
          property="og:description" 
          content={`Generate professional ${documentType.toLowerCase()}s specifically designed for ${industry.toLowerCase()}. Free online ${documentType.toLowerCase()} generator with industry-specific templates.`} 
        />
        <meta property="og:type" content="website" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-br from-blue-900 to-blue-800 pt-32 pb-20 sm:pt-40 sm:pb-32">
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                {capitalizedDocType} Generator for
                <br />
                <span className="text-blue-400">{capitalizedIndustry}</span>
              </h1>
              <p className="mx-auto mt-6 max-w-2xl text-lg text-blue-100">
                Create professional {documentType.toLowerCase()}s tailored specifically for {industry.toLowerCase()}.
                No design skills required.
              </p>
              <div className="mt-10 flex justify-center gap-4">
                <Link
                  to={`/${documentType.toLowerCase()}/generate`}
                  className="inline-flex items-center px-6 py-3 bg-white text-blue-900 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
                >
                  Create Your {capitalizedDocType}
                  <ArrowForward className="ml-2 w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold text-slate-900">
                Why Use Our {capitalizedDocType} Generator?
              </h2>
              <p className="mt-4 text-lg text-slate-600">
                Everything you need to create professional {documentType.toLowerCase()}s for your {industry.toLowerCase()} business
              </p>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <div
                  key={index}
                  className="relative p-8 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className={`${benefit.color} w-12 h-12 rounded-xl flex items-center justify-center mb-6`}>
                    <benefit.icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-xl font-semibold text-slate-900 mb-2">{benefit.title}</h3>
                  <p className="text-slate-600">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-blue-900">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-3xl font-bold text-white mb-8">
              Ready to Create Your Professional {capitalizedDocType}?
            </h2>
            <p className="text-lg text-blue-100 mb-10 max-w-2xl mx-auto">
              Join thousands of {industry.toLowerCase()} professionals who trust our {documentType.toLowerCase()} generator
            </p>
            <Link
              to={`/${documentType.toLowerCase()}/generate`}
              className="inline-flex items-center px-8 py-4 bg-white text-blue-900 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
            >
              Get Started Now
              <ArrowForward className="ml-2" />
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}